<template>
    <main class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3">
                <div class="container">
                    <div class="grid align-items-center">
                        <div v-if="!isSubPage" class="col-fixed ">
                            <Button @click="$router.go(-1)" label="" className="p-button p-button-text "
                                icon="pi pi-arrow-left" />
                        </div>
                        <div class="col ">
                            <div class="">
                                <div class="flex align-items-center ">
                                    <div class="mr-3">
                                        <Avatar size="large" class="" icon="pi pi-user-edit" />
                                    </div>
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Edit User Account</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section ">
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid">
                        <div>
                            <template v-if="pageReady">
                                <form ref="observer" tag="form" @submit.prevent="submitForm()"
                                    :class="{ 'card': !isSubPage }" class="">
                                    <!--[form-content-start]-->
                                    <div class="grid">
                                        <div class="col-12 md:col-6">
                                            <div class="mb-2 font-bold">First Name *</div>
                                            <div id="ctrl-first_name-holder">
                                                <InputText ref="ctrlfirst_name" v-model.trim="formData.first_name"
                                                    label="First Name" type="text" placeholder="Enter First Name"
                                                    class=" w-full" :class="getErrorClass('first_name')">
                                                </InputText>
                                                <small v-if="isFieldValid('first_name')" class="p-error">{{
                                                    getFieldError('first_name') }}</small>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-6">
                                            <div class="mb-2 font-bold">Last Name *</div>
                                            <div id="ctrl-last_name-holder">
                                                <InputText ref="ctrllast_name" v-model.trim="formData.last_name"
                                                    label="Last Name" type="text" placeholder="Enter Last Name"
                                                    class=" w-full" :class="getErrorClass('last_name')">
                                                </InputText>
                                                <small v-if="isFieldValid('last_name')" class="p-error">{{
                                                    getFieldError('last_name') }}</small>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-6">
                                            <div class="mb-2 font-bold"> Mobile Number *</div>
                                            <div id="ctrl-mobile_number-holder">
                                                <check-duplicate v-model="formData.mobile_number"
                                                    check-path="components_data/users_mobile_number_exist/"
                                                    v-slot="checker">
                                                    <InputText ref="ctrlmobile_number" @blur="checker.check"
                                                        :loading="checker.loading" v-model.trim="formData.mobile_number"
                                                        label="Mobile Number" type="text"
                                                        placeholder="Enter Mobile Number" class=" w-full"
                                                        :class="getErrorClass('mobile_number')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('mobile_number')" class="p-error">{{
                                                        getFieldError('mobile_number') }}</small>
                                                    <small v-if="!checker.loading && checker.exist" class="p-error"> Not
                                                        available</small>
                                                    <small v-if="checker.loading" class="text-500">Checking...</small>
                                                </check-duplicate>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-6">
                                            <div class="mb-2 font-bold"> Account Status *</div>
                                            <div id="ctrl-account_status-holder">
                                                <Dropdown class="w-full" :class="getErrorClass('account_status')"
                                                    optionLabel="label" optionValue="value" ref="ctrlaccount_status"
                                                    v-model="formData.account_status"
                                                    :options="app.menus.accountStatusItems" label="Account Status"
                                                    placeholder="Select ...">
                                                </Dropdown>
                                                <small v-if="isFieldValid('account_status')" class="p-error">{{
                                                    getFieldError('account_status') }}</small>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-6">
                                            <div class="mb-2 font-bold">User Type *</div>
                                            <div id="ctrl-user_type-holder">
                                                <Dropdown class="w-full" :class="getErrorClass('user_type')"
                                                    optionLabel="label" optionValue="value" ref="ctrluser_type"
                                                    v-model="formData.user_type" :options="app.menus.userTypeItems"
                                                    label="User Type" placeholder="Select a value ...">
                                                </Dropdown>
                                                <small v-if="isFieldValid('user_type')" class="p-error">{{
                                                    getFieldError('user_type') }}</small>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="mb-2 font-bold"> Photo *</div>
                                            <div id="ctrl-photo-holder">
                                                <div class="mb-3">
                                                    <Uploader :class="getErrorClass('photo')" :auto="true"
                                                        :fileLimit="1" :maxFileSize="3000000"
                                                        accept=".jpg,.png,.gif,.jpeg" :multiple="false"
                                                        style="width:100%" label="Choose files or drop files here"
                                                        upload-path="fileuploader/upload/photo"
                                                        v-model="formData.photo"></Uploader>
                                                </div>
                                                <small v-if="isFieldValid('photo')" class="p-error">{{
                                                    getFieldError('photo') }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <!--[form-content-end]-->
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </template>
                            <template v-if="loading">
                                <div class="p-3 text-center">
                                    <ProgressSpinner style="width:50px;height:50px" />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script setup>
    import { computed, reactive, toRefs, onMounted } from 'vue';
    import useVuelidate from '@vuelidate/core';
    import { required, minLength, maxLength, } from '@/services/validators';
    import { useApp } from '@/composables/app.js';
    import { useEditPage } from '@/composables/editpage.js';
    const props = defineProps({
        id: [String, Number],
        pageName: {
            type: String,
            default: 'users',
        },
        routeName: {
            type: String,
            default: 'usersedit',
        },
        pagePath: {
            type: String,
            default: 'users/edit',
        },
        apiPath: {
            type: String,
            default: 'users/edit',
        },
        submitButtonLabel: {
            type: String,
            default: "Update",
        },
        formValidationError: {
            type: String,
            default: "Form is invalid",
        },
        formValidationMsg: {
            type: String,
            default: "Please complete the form",
        },
        msgTitle: {
            type: String,
            default: 'Update Record',
        },
        msgBeforeSave: {
            type: String,
            default: "",
        },
        msgAfterSave: {
            type: String,
            default: "Record updated successfully",
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showSubmitButton: {
            type: Boolean,
            default: true,
        },
        redirect: {
            type: Boolean,
            default: true,
        },
        isSubPage: {
            type: Boolean,
            default: false,
        },
    });
    const app = useApp();
    const formDefaultValues = Object.assign({
        first_name: "",
        last_name: "",
        mobile_number: "",
        user_type: "",
        photo: "",
        account_status: "Active",
    }, props.pageData);
    const formData = reactive({ ...formDefaultValues });
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        if (app.isDialogOpen()) {
            app.closeDialogs(); // if page is open as dialog, close dialog
        }
        else if (props.redirect) {
            app.navigateTo(`/users`);
        }
    }
    const rules = computed(() => {
        return {
            first_name: { required },
            last_name: { required },
            mobile_number: { required, minLength: minLength(10), maxLength: maxLength(10) },
            user_type: { required },
            photo: { required },
            account_status: { required }
        }
    });
    const v$ = useVuelidate(rules, formData); //form validation
    const page = useEditPage({ props, formData, v$, afterSubmit });
    const { submitted, saving, loading, pageReady } = toRefs(page.state);
    const { apiUrl, currentRecord } = page.computedProps;
    const { load, submitForm, getErrorClass, getFieldError, isFieldValid, mapOptionField } = page.methods;
    onMounted(() => {
        const pageTitle = "Edit User Account";
        app.setPageTitle(props.routeName, pageTitle); // set browser page title
        load();
    });
</script>
<style scoped>
</style>